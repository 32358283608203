<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Category">
            <validation-observer ref="updateCategoryValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter category name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        image: null,
      },
      currentData: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchCategory()
    this.loader = false
  },
  methods: {
    ...mobileTabletModule.mapActions(['FETCH_SINGLE_CATEGORY', 'UPDATE_CATEGORY']),

    async fetchCategory() {
      try {
        this.loader = true
        const resp = await this.FETCH_SINGLE_CATEGORY(this.$route.params.id)
        this.form.name = resp.name
        this.currentData = resp
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updateCategoryValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const formData = new FormData()
            if (this.currentData.name !== this.form.name) {
              formData.append('name', this.form.name)
            }
            if (this.form.image) {
              formData.append('image', this.form.image)
            }
            const resp = await this.UPDATE_CATEGORY({ id: this.$route.params.id, data: formData })

            if (resp) {
              this.$nextTick(() => {
                this.$refs.updateCategoryValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Category Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The category has been updated successfully!',
                },
              })
            }
            this.$router.push('/mobile-tablet/category')
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
